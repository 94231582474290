<template>
  <div class="my-account-container">
    <div class="my-account-account-top">
      <div class="w-full max-w-rifyo mx-auto">
        <h1 class="my-account-text">
          <span class="my-account-text01"></span>
          <span class="my-account-text02">My Account</span>
        </h1>
      </div>
    </div>
    <div class="my-account-account-detail">
      <div class="w-full max-w-rifyo mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full lg:w-5/12 px-4">
            <div class="my-account-container-account">
              <div class="my-account-account-information">
                <h5 class="my-account-text03">Account Information</h5>
                <div class="cursor-pointer" @click="goPage('edit-info')">
                  <text-arrow></text-arrow>
                </div>
              </div>
              <div class="my-account-container-detail">
                <div class="my-account-name">
                  <img
                    src="/playground_assets/user.svg"
                    alt="image"
                    class="my-account-image"
                  />
                  <span class="my-account-text04">{{
                    profile ? profile.name : ''
                  }}</span>
                </div>
                <div class="my-account-phone">
                  <img
                    src="/playground_assets/phone.svg"
                    alt="image"
                    class="my-account-image1"
                  />
                  <span
                    class="my-account-text05"
                    :class="!profile.phone ? 'opacity-50' : ''"
                    >{{
                      profile.phone
                        ? profile.phone
                        : 'No phone information yet.'
                    }}</span
                  >
                </div>
                <div class="my-account-email">
                  <img
                    src="/playground_assets/mail.svg"
                    alt="image"
                    class="my-account-image2"
                  />
                  <span
                    class="my-account-text06"
                    :class="!profile.email ? 'opacity-50' : ''"
                    >{{
                      profile.email
                        ? profile.email
                        : 'No email information yet.'
                    }}</span
                  >
                </div>
              </div>
              <hr v-if="screen.width >= 992" />
              <div class="my-account-account-password">
                <h5 class="my-account-text03">Edit Password</h5>
                <div class="cursor-pointer" @click="goPage('edit-password')">
                  <text-arrow text="Change"></text-arrow>
                </div>
              </div>
              <div class="my-account-container-password">
                <h1 class="my-account-password">********</h1>
              </div>
              <hr v-if="screen.width >= 992" />
              <div class="my-account-account-password1">
                <h5 class="my-account-text03">Shipping Information</h5>
                <div class="cursor-pointer" @click="goPage('edit-shipping')">
                  <text-arrow text="Edit"></text-arrow>
                </div>
              </div>
              <div v-if="isFetching" class="my-account-container-address">
                <Loading />
              </div>
              <div v-else class="my-account-container-address">
                <span class="my-account-text09">
                  <template
                    v-if="
                      shipping &&
                      shipping.address &&
                      shipping.subdistrict.name &&
                      shipping.city.name &&
                      shipping.zipcode
                    "
                  >
                    <span>{{
                      shipping.address + ', ' + shipping.subdistrict.name
                    }}</span>
                    <br />
                    <span>{{ shipping.city.name }}</span>
                    <br />
                    <span>{{ shipping.zipcode }}</span>
                  </template>
                  <template v-else>
                    <span class="opacity-50">No shipping information yet.</span>
                  </template>
                </span>
              </div>
              <hr v-if="screen.width >= 992" />
            </div>
          </div>
          <div class="w-full lg:w-7/12 px-4">
            <div class="flex flex-col">
              <div class="my-account-container-order">
                <div class="my-account-account-information1">
                  <h5 class="my-account-text03">Order</h5>
                  <div v-if="orderList.length > 0" class="cursor-pointer" @click="goPage('order-list')">
                    <text-arrow text="See All"></text-arrow>
                  </div>
                </div>
                <div v-if="isFetching" class="w-full py-10">
                  <Loading />
                </div>
                <template v-else>
                  <div
                    class="w-full py-8 flex flex-col align-center justify-center"
                    v-if="!lastOrders.length"
                  >
                    <img
                      class="mb-8 w-20 mx-auto"
                      src="/playground_assets/list.svg"
                      alt="Shopping Cart"
                    />
                    <div class="mb-4 empty-cart-h1">
                      You don’t have any order yet.
                    </div>
                    <div class="mb-8 empty-cart-h4">
                      You haven’t added anything to your cart.
                    </div>
                    <div class="text-center">
                      <button
                        class="px-8 py-2 empty-cart-btn"
                        @click="$router.push('/shop/all')"
                        style="background-color: #1f252c; color: white"
                      >
                        Browse Products
                      </button>
                    </div>
                  </div>
                  <template v-else>
                    <div
                      class="my-account-item-order"
                      v-for="item in lastOrders"
                      :key="item.id"
                    >
                      <span class="my-account-text14">{{
                        formatDate(item.created_at)
                      }}</span>
                      <span class="my-account-text15">
                        <span class="my-account-text16"></span>
                        <span class="my-account-text17">{{
                          item.invoice_number
                        }}</span>
                      </span>
                      <div class="my-account-container1 flex-col md:flex-row gap-4 items-start">
                        <div
                          class="my-account-btn-delivery"
                          v-if="item.status == 'order_complete'"
                        >
                          <span class="my-account-text18">Completed</span>
                        </div>
                        <div
                          class="my-account-btn-paid"
                          v-if="item.status == 'paid'"
                        >
                          <span class="my-account-text18">In Process</span>
                        </div>
                        <div
                          class="my-account-btn-paid"
                          v-if="item.status == 'paid_off'"
                        >
                          <span class="my-account-text18">In Process</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'waiting_for_product'"
                        >
                          <span class="my-account-text18">Waiting for Arrival</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'product_is_ready'"
                        >
                          <span class="my-account-text18">Arrived, waiting for payment</span>
                        </div>
                        <div
                          class="my-account-btn-expired"
                          v-if="item.status == 'unsuccess'"
                        >
                          <span class="my-account-text18">Waiting For Payment</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'pending'"
                        >
                          <span class="my-account-text18">Pending</span>
                        </div>
                        <div
                          class="my-account-btn-send"
                          v-if="item.status == 'order_sent'"
                        >
                          <span class="my-account-text18">On Delivery</span>
                        </div>
                        <div
                          class="my-account-btn-expired"
                          v-if="item.status == 'expired'"
                        >
                          <span class="my-account-text18">Cancelled</span>
                        </div>
                        <div
                          class="my-account-btn-new"
                          v-if="item.status == 'new_order'"
                        >
                          <span class="my-account-text18">New Order</span>
                        </div>
                        <div class="my-account-btn-details">
                          <span class="my-account-text19">
                            <span class="my-account-text20"></span>
                            <div
                              class="cursor-pointer"
                              @click="goPage('order-detail/' + item.id)"
                            >
                              <span class="my-account-text21">See Details</span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <!-- <hr :class="item.divider" v-if="screen.width >= 992" /> -->
                    </div>
                  </template>
                </template>
              </div>
              <div class="my-account-container-order">
                <div class="my-account-account-information1">
                  <h5 class="my-account-text03">Order Indent</h5>
                  <div v-if="orderList.length > 0" class="cursor-pointer" @click="goPage('order-list')">
                    <text-arrow text="See All"></text-arrow>
                  </div>
                </div>
                <div v-if="isFetching" class="w-full py-10">
                  <Loading />
                </div>
                <template v-else>
                  <div
                    class="w-full py-8 flex flex-col align-center justify-center"
                    v-if="!lastIndents.length"
                  >
                    <img
                      class="mb-8 w-20 mx-auto"
                      src="/playground_assets/list.svg"
                      alt="Shopping Cart"
                    />
                    <div class="mb-4 empty-cart-h1">
                      You don’t have any indent order yet.
                    </div>
                    <div class="mb-8 empty-cart-h4">
                      You haven’t added anything to your cart.
                    </div>
                    <div class="text-center">
                      <button
                        class="px-8 py-2 empty-cart-btn"
                        @click="$router.push('/shop/all')"
                        style="background-color: #1f252c; color: white"
                      >
                        Browse Products
                      </button>
                    </div>
                  </div>
                  <template v-else>
                    <div
                      class="my-account-item-order"
                      v-for="item in lastIndents"
                      :key="item.id"
                    >
                      <span class="my-account-text14">{{
                        formatDate(item.created_at)
                      }}</span>
                      <span class="my-account-text15">
                        <span class="my-account-text16"></span>
                        <span class="my-account-text17">{{
                          item.invoice_number
                        }}</span>
                      </span>
                      <div class="my-account-container1 flex-col md:flex-row gap-4 items-start">
                        <div
                          class="my-account-btn-delivery"
                          v-if="item.status == 'order_complete'"
                        >
                          <span class="my-account-text18">Completed</span>
                        </div>
                        <div
                          class="my-account-btn-paid"
                          v-if="item.status == 'paid'"
                        >
                          <span class="my-account-text18">In Process</span>
                        </div>
                        <div
                          class="my-account-btn-paid"
                          v-if="item.status == 'paid_off'"
                        >
                          <span class="my-account-text18">In Process</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'waiting_for_product'"
                        >
                          <span class="my-account-text18">Waiting for Arrival</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'pending'"
                        >
                          <span class="my-account-text18">Pending</span>
                        </div>
                        <div
                          class="my-account-btn-waiting"
                          v-if="item.status == 'product_is_ready'"
                        >
                          <span class="my-account-text18">Arrived, waiting for payment</span>
                        </div>
                        <div
                          class="my-account-btn-expired"
                          v-if="item.status == 'unsuccess'"
                        >
                          <span class="my-account-text18">Waiting For Payment</span>
                        </div>
                        <div
                          class="my-account-btn-send"
                          v-if="item.status == 'order_sent'"
                        >
                          <span class="my-account-text18">On Delivery</span>
                        </div>
                        <div
                          class="my-account-btn-expired"
                          v-if="item.status == 'expired'"
                        >
                          <span class="my-account-text18">Cancelled</span>
                        </div>
                        <div
                          class="my-account-btn-new"
                          v-if="item.status == 'new_order'"
                        >
                          <span class="my-account-text18">New Order</span>
                        </div>
                        <div
                          class="my-account-btn-details cursor-pointer"
                          @click="goPage('order-detail/' + item.id)"
                        >
                          <span class="my-account-text19">
                            <span class="my-account-text20"></span>
                            <div>
                              <span class="my-account-text21">See Details</span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import TextArrow from '../components/text-arrow';
  import Footer from '../components/footer';
  import Api from '../api';
  import moment from 'moment';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'MyAccount',
    props: {},
    data() {
      return {
        orderList: [],
        shipping: {
          address: null,
          city: {
            name: null,
          },
          subdistrict: {
            name: null,
          },
          zipcode: null,
        },
        screen: {
          width: screen.width,
        },
        isFetching: false,
      };
    },
    components: {
      TextArrow,
      Footer,
      Loading,
    },
    computed: {
      profile() {
        return this.$store.state.user;
      },
      lastOrders() {
        const count = 2;
        let orders = [];
        if (this.orderList.length) {
          for (let index = 0; index < this.orderList.length; index++) {
            if (this.orderList[index].product.length && orders.length < count) {
              orders.push({
                ...this.orderList[index],
                divider: orders.length < count - 1 ? 'dotted' : 'w-10/12',
              });
            }
          }
        }
        return orders;
      },
      lastIndents() {
        const count = 2;
        let indents = [];
        if (this.orderList.length) {
          for (let index = 0; index < this.orderList.length; index++) {
            if (
              this.orderList[index].product_indent.length &&
              indents.length < count
            ) {
              indents.push({
                ...this.orderList[index],
                divider: indents.length < count - 1 ? 'dotted' : 'w-10/12',
              });
            }
          }
        }
        return indents;
      },
    },
    async mounted() {
      await this.loadData();
    },
    methods: {
      async loadData() {
        try {
          this.isFetching = true;
          // fetch shipping address
          const shipping = await axios.get(`${Api.profileShippingUrl}`);
          this.shipping = shipping.data.data;
          // fetch order list
          const order = await axios.get(Api.orderUrl);
          this.orderList = order.data.data;

          window.addEventListener('resize', () => {
            this.screen.width = screen.width;
          });
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isFetching = false;
        }
      },
      goPage(page) {
        this.$router.push(page);
      },
      formatDate(date) {
        return moment(date).format('D MMMM YYYY');
      },
    },
  };
</script>

<style scoped>
  .empty-cart-h1 {
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }
  .empty-cart-h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .empty-cart-btn {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-align: left;
  }
  hr {
    border-color: #aea79d;
    border-style: solid;
    border-width: 1px 0 0 0;
    margin-top: 16px;
    width: 100%;
  }
  hr.dotted {
    border-style: dotted;
  }
  .my-account-logout {
    font-size: 18px;
    font-weight: 400;
    margin-left: 16px;
  }
  .my-account-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .my-account-account-top {
    width: 100%;
    /* height: 269px; */
    display: flex;
    align-items: flex-end;
    padding-top: 120px;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .my-account-text {
    font-size: 3em;
    font-family: Lora;
  }
  .my-account-text01 {
    font-family: Lora;
  }
  .my-account-text02 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    color: #1f252c;
  }
  .my-account-account-detail {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 140px;
    padding-right: 140px;
    background-color: #eae3d9;
  }
  .my-account-container-account {
    flex: 40%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .my-account-account-information {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .my-account-text03 {
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f252c;
  }
  .my-account-container-detail {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .my-account-name {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-image {
    width: 24px;
    object-fit: cover;
  }
  .my-account-text04 {
    font-family: Montserrat;
    margin-left: var(--dl-space-space-unit);
  }
  .my-account-phone {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-image1 {
    width: 24px;
    object-fit: cover;
  }
  .my-account-text05 {
    font-family: Montserrat;
    margin-left: var(--dl-space-space-unit);
  }
  .my-account-email {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-image2 {
    width: 24px;
    object-fit: cover;
  }
  .my-account-text06 {
    font-family: Montserrat;
    margin-left: var(--dl-space-space-unit);
  }
  .my-account-account-password {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .my-account-text07 {
    font-size: 24px;
    font-family: Lora;
  }
  .my-account-container-password {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .my-account-password {
    font-size: 1.5em;
  }
  .my-account-account-password1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .my-account-text08 {
    font-size: 24px;
    font-family: Lora;
  }
  .my-account-container-address {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-text09 {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .my-account-container-order {
    flex: 60%;
    display: flex;
    align-items: flex-start;
    padding-left: 80px;
    flex-direction: column;
  }
  .my-account-account-information1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .my-account-text13 {
    font-size: 24px;
    font-family: Lora;
  }
  .my-account-item-order {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    border-bottom: 1px dashed #aea79d;
    padding-bottom: 30px;
  }

  .my-account-item-order:last-of-type {
    border-bottom: 1px solid #aea79d;
  }
  .my-account-text14 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-text15 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .my-account-text16 {
    font-family: Lora;
  }
  .my-account-text17 {
    font-family: Lora;
  }
  .my-account-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .my-account-btn-delivery {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
    padding: 0 20px;
  }
  .my-account-btn-paid {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f6c23e;
    padding: 0 20px;
  }
  .my-account-btn-waiting {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #36b9cc;
    padding: 0 20px;
  }
  .my-account-btn-send {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #36b9cc;
    padding: 0 20px;
  }
  .my-account-btn-expired {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e74a3b;
    padding: 0 20px;
  }
  .my-account-btn-new {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #858796;
    padding: 0 20px;
  }
  .my-account-text18 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .my-account-btn-details {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .my-account-text19 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .my-account-text20 {
    font-family: Montserrat;
  }
  .my-account-text21 {
    font-family: Montserrat;
  }
  .my-account-item-order1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .my-account-text22 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-text23 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .my-account-text24 {
    font-family: Lora;
  }
  .my-account-text25 {
    font-family: Lora;
  }
  .my-account-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .my-account-btn-delivery1 {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
  }
  .my-account-text26 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .my-account-btn-details1 {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .my-account-text27 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .my-account-text28 {
    font-family: Montserrat;
  }
  .my-account-text29 {
    font-family: Montserrat;
  }
  .my-account-item-order2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .my-account-text30 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .my-account-text31 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .my-account-text32 {
    font-family: Lora;
  }
  .my-account-text33 {
    font-family: Lora;
  }
  .my-account-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .my-account-btn-delivery2 {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
  }
  .my-account-text34 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .my-account-btn-details2 {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .my-account-text35 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .my-account-text36 {
    font-family: Montserrat;
  }
  .my-account-text37 {
    font-family: Montserrat;
  }
  @media (max-width: 991px) {
    .my-account-account-top {
      width: 100%;
      /* height: 269px; */
      display: flex;
      align-items: flex-end;
      padding-top: 120px;
      padding-left: 140px;
      padding-right: 140px;
      padding-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .my-account-text {
      font-size: 3em;
      font-family: Lora;
    }
    .my-account-text01 {
      font-family: Lora;
    }
    .my-account-text02 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      color: #1f252c;
    }
    .my-account-account-detail {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      padding-bottom: var(--dl-space-space-threeunits);
      padding-top: var(--dl-space-space-threeunits);
      padding-left: 140px;
      padding-right: 140px;
      background-color: #eae3d9;
    }
    .my-account-container-account {
      flex: 40%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .my-account-account-information {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .my-account-text03 {
      font-family: Lora;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #1f252c;
    }
    .my-account-container-detail {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .my-account-name {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-image {
      width: 24px;
      object-fit: cover;
    }
    .my-account-text04 {
      font-family: Montserrat;
      margin-left: var(--dl-space-space-unit);
    }
    .my-account-phone {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-image1 {
      width: 24px;
      object-fit: cover;
    }
    .my-account-text05 {
      font-family: Montserrat;
      margin-left: var(--dl-space-space-unit);
    }
    .my-account-email {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-image2 {
      width: 24px;
      object-fit: cover;
    }
    .my-account-text06 {
      font-family: Montserrat;
      margin-left: var(--dl-space-space-unit);
    }
    .my-account-account-password {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      margin-top: var(--dl-space-space-oneandhalfunits);
      align-items: center;
      margin-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .my-account-text07 {
      font-size: 24px;
      font-family: Lora;
    }
    .my-account-container-password {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .my-account-password {
      font-size: 1.5em;
    }
    .my-account-account-password1 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      margin-top: var(--dl-space-space-oneandhalfunits);
      align-items: center;
      margin-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .my-account-text08 {
      font-size: 24px;
      font-family: Lora;
    }
    .my-account-container-address {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-text09 {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .my-account-container-order {
      flex: 60%;
      display: flex;
      align-items: flex-start;
      padding-left: 80px;
      flex-direction: column;
    }
    .my-account-account-information1 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .my-account-text13 {
      font-size: 24px;
      font-family: Lora;
    }
    .my-account-item-order {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .my-account-text14 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-text15 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .my-account-text16 {
      font-family: Lora;
    }
    .my-account-text17 {
      font-family: Lora;
    }
    .my-account-container1 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .my-account-btn-delivery {
      flex: 0 0 auto;

      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .my-account-btn-expired {
      flex: 0 0 auto;

      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #7d1f1f;
    }
    .my-account-btn-new {
      flex: 0 0 auto;

      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f257d;
    }
    .my-account-text18 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .my-account-btn-details {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .my-account-text19 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .my-account-text20 {
      font-family: Montserrat;
    }
    .my-account-text21 {
      font-family: Montserrat;
    }
    .my-account-item-order1 {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .my-account-text22 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-text23 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .my-account-text24 {
      font-family: Lora;
    }
    .my-account-text25 {
      font-family: Lora;
    }
    .my-account-container2 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .my-account-btn-delivery1 {
      flex: 0 0 auto;

      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .my-account-text26 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .my-account-btn-details1 {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .my-account-text27 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .my-account-text28 {
      font-family: Montserrat;
    }
    .my-account-text29 {
      font-family: Montserrat;
    }
    .my-account-item-order2 {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .my-account-text30 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .my-account-text31 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .my-account-text32 {
      font-family: Lora;
    }
    .my-account-text33 {
      font-family: Lora;
    }
    .my-account-container3 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .my-account-btn-delivery2 {
      flex: 0 0 auto;

      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .my-account-text34 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .my-account-btn-details2 {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .my-account-text35 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .my-account-text36 {
      font-family: Montserrat;
    }
    .my-account-text37 {
      font-family: Montserrat;
    }
    @media (max-width: 991px) {
      .my-account-account-top {
        padding-left: 16px;
        padding-right: 16px;
      }
      .my-account-account-detail {
        flex-wrap: wrap;
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
      }
      .my-account-container-account {
        flex: 100%;
      }
      .my-account-container-detail {
        border-bottom: 1px solid #fff;
        padding-bottom: 32px;
      }
      .my-account-container-password {
        border-bottom: 1px solid #fff;
        padding-bottom: 32px;
      }
      .my-account-container-address {
        border-bottom: 1px solid #fff;
        padding-bottom: 32px;
      }
      .my-account-container-order {
        flex: 100%;
        margin-top: var(--dl-space-space-twounits);
        padding-left: 0px;
      }
      /* .my-account-item-order {
      border-bottom: 1px solid #fff;
      padding-bottom: 32px;
    }
    .my-account-item-order1 {
      border-bottom: 1px solid #fff;
      padding-bottom: 32px;
    } */
    }
  }
</style>
