<template>
  <div class="text-arrow-container">
    <span class="text-arrow-text">{{ text }}</span>
    <img :alt="image_alt" :src="image_src" class="text-arrow-image" />
  </div>
</template>

<script>
export default {
  name: 'TextArrow',
  props: {
    text: {
      type: String,
      default: 'Edit',
    },
    image_src: {
      type: String,
      default: '/playground_assets/arrow-right-black.svg',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.text-arrow-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.text-arrow-text {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  margin-right: var(--dl-space-space-halfunit);
  letter-spacing: 4px;
}
.text-arrow-image {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
</style>
